export const groupIcon = [
    {
      src: "https://image.investmango.com/images/img/ACE.jpg",
      alt: "Ace Group",
      title: "Ace Group",
    },
    {
      src: "https://image.investmango.com/images/img/Lodha.jpg",
      alt: "Lodha Group",
      title: "Lodha Group",
    },
    // {
    //   src: "https://imagedelivery.net/MbjggtGD4dFDFpyznW77nA/c38da8af-989d-43f7-ba71-9443f2a83600/public",
    //   alt: "Raheja",
    //   title: "Raheja",
    // },
    // {
    //   src: "https://imagedelivery.net/MbjggtGD4dFDFpyznW77nA/76635ae7-c537-4e9f-54b1-fd641f163e00/public",
    //   alt: "Devsai SportsHome",
    //   title: "Devsai SportsHome",
    // },
    {
      src: "https://image.investmango.com/images/img/Signature.jpg",
      alt: "Signature Global India",
      title: "Signature Global India",
    },
    {
      src: "https://image.investmango.com/images/img/Nirala.jpg",
      alt: "Nirala Group",
      title: "Nirala Group",
    },
    {
      src: "https://imagedelivery.net/MbjggtGD4dFDFpyznW77nA/e79f5753-87b2-4ab4-8819-516a4ce0e500/public",
      alt: "Godrej Properties",
      title: "Godrej Properties",
    },
    {
      src: "https://image.investmango.com/images/img/Kolte.jpg",
      alt: "Kolte Patil",
      title: "Kolte Patil",
    },
    {
      src: "https://imagedelivery.net/MbjggtGD4dFDFpyznW77nA/89db5220-032e-4768-1301-932653850300/public",
      alt: "Tata Value Homes",
      title: "Tata Value Homes",
    },
    {
      src: "https://imagedelivery.net/MbjggtGD4dFDFpyznW77nA/7eacab40-769b-4d5c-8633-160844f84000/public",
      alt: "DLF",
      title: "DLF",
    },
    // {
    //   src: "https://imagedelivery.net/MbjggtGD4dFDFpyznW77nA/1751a5a6-7584-452c-f2c7-9e15bd3b4600/public",
    //   alt: "Bhutani Infra",
    //   title: "Bhutani Infra",
    // },
    {
      src: "https://image.investmango.com/images/img/Sobha.jpg",
      alt: "Sobha Limited",
      title: "Sobha",
    },
    // {
    //   src: "https://imagedelivery.net/MbjggtGD4dFDFpyznW77nA/963eace5-a6e7-4713-6c8e-88d90955c900/public",
    //   alt: "Risland",
    //   title: "Risland",
    // },
    {
      src: "https://image.investmango.com/images/img/Max.jpg",
      alt: "Max Group",
      title: "Max Group",
    },
    {
      src: "https://imagedelivery.net/MbjggtGD4dFDFpyznW77nA/629090cc-a88a-4f0c-f90d-3602c6c0da00/public",
      alt: "Eldeco",
      title: "Eldeco",
    },
    {
      src: "https://image.investmango.com/images/img/Conscient.jpg",
      alt: "Conscient Infrastructure",
      title: "Conscient Infrastructure",
    },

    {
      src: "https://image.investmango.com/images/img/VTP.jpg",
      alt: "VTP Realty",
      title: "VTP Realty",
    },
  
    {
      src: "https://imagedelivery.net/MbjggtGD4dFDFpyznW77nA/8c629361-cbe0-4af0-71de-b516032d5900/public",
      alt: "ATS Group",
      title: "ATS Group",
    },
    {
      src: "https://image.investmango.com/images/img/Birla.jpg",
      alt: "Birla Estate",
      title: "Birla Estate",
    },
  ];
  